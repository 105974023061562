import { promiseState } from "../../helper"
import { church } from "../../redux/actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	errors: [],
	message: "",
	hasMore: false,
	data: [],
	page: {
		totalPage: 0,
		currentPage: 1,
		totalData: 0,
	},
	detail: {},
}

function churchReducer(state = initialState, action) {
	switch (action.type) {
		case pendingState(church.GET_CHURCHES): {
			return Object.assign({}, state, {
				isLoading: true,
				hasMore: true,
			})
		}

		case rejectedState(church.GET_CHURCHES): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(church.GET_CHURCHES): {
			const hasMore =
				action.payload.data.data.length < action.payload.data.page.totalData
			return Object.assign({}, state, {
				isLoading: false,
				hasMore,
				data: action.payload.data.data,
				page: {
					totalPage: action.payload.data.page.totalPage,
					currentPage: action.payload.data.page.currentPage,
					totalData: action.payload.data.page.totalData,
				},
			})
		}

		case pendingState(church.GET_DETAIL_CHURCH): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(church.GET_DETAIL_CHURCH): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(church.GET_DETAIL_CHURCH): {
			return Object.assign({}, state, {
				isLoading: false,
				detail: action.payload.data.data,
			})
		}

		case pendingState(church.NEXT_CHURCHES): {
			return Object.assign({}, state, {
				isLoading: true,
				hasMore: true,
			})
		}

		case rejectedState(church.NEXT_CHURCHES): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(church.NEXT_CHURCHES): {
			const hasMore =
				state.data.concat(action.payload.data.data).length <
				action.payload.data.page.totalData
			return Object.assign({}, state, {
				isLoading: false,
				hasMore,
				data: state.data.concat(action.payload.data.data),
				page: {
					totalPage: action.payload.data.page.totalPage,
					currentPage: action.payload.data.page.currentPage,
					totalData: action.payload.data.page.totalData,
				},
			})
		}

		default:
			return state
	}
}

export default churchReducer
