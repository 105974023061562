export const banner = {
	GET_EVENT_BANNER: "banner/event/get",
	GET_BANNER: "banner/info/get",
	ADD_EVENT_BANNER: "banner/event/add",
	ADD_INFO_BANNER: "banner/info/add",
	DELETE_EVENT_BANNER: "banner/event/delete",
	DELETE_INFO_BANNER: "banner/info/delete",
}

export default banner
