import getLastMagazine from "./getLastMagazine"
import changeMagazine from "./changeMagazine"

const magazine = {
	getLastMagazine,
	changeMagazine,
}

export { getLastMagazine, changeMagazine }

export default magazine
