import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Breadcrumb,
	BreadcrumbItem,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { connect } from "react-redux"
import { resource } from "../../../redux/actions"
import Swal from "sweetalert2"

class CreateResource extends Component {
	constructor(props) {
		super(props)

		this.state = {
			title: "",
			price: "",
			description: "",
			image: null,
			wa_phone: "",
			isModalShow: false,
			showImage: null,

			readOnly: this.props.match.params.resourceId !== "create",

			editMode: false,
		}
	}

	handleDelete() {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				this.props
					.deleteResource(this.props.match.params.resourceId)
					.then(() => {
						Swal.fire("Deleted!", "Resource has been deleted.", "success")
						this.props.history.push(`/admin/resource`)
					})
			}
		})
	}

	modalToggle() {
		this.setState({
			isModalShow: !this.state.isModalShow,
		})
	}

	goBack() {
		if (this.props.match.params.resourceId === "create") {
			this.props.history.push("/admin/resource")
		}

		return this.setState({
			readOnly: !this.state.readOnly,
		})
	}

	addResource() {
		const formData = new FormData()

		formData.append("title", this.state.title)
		formData.append("price", this.state.price)
		formData.append("wa_phone", this.state.wa_phone)
		formData.append("description", this.state.description)
		formData.append("image", this.state.image[0])

		this.props.addResource(formData).then(() => {
			toast.success("Berhasil Tambah Resource")
			this.props.history.push("/admin/resource")
		})
	}

	getResource() {
		this.props.getResource(this.props.match.params.resourceId).then(() => {
			const detail = this.props.resource.detail
			this.setState({
				title: detail.title,
				price: Number(detail.price),
				description: detail.description,
				wa_phone: detail.wa_phone,
				isModalShow: false,
				showImage: detail.image,
			})
		})
	}

	componentDidMount() {
		if (this.state.readOnly) {
			this.getResource()
		}
	}

	proxySubmit(e) {
		e.preventDefault()

		if (this.props.match.params.resourceId === "create") {
			return this.addResource()
		}

		return this.updateResource()
	}

	updateResource() {
		const formData = new FormData()

		formData.append("title", this.state.title)
		formData.append("price", this.state.price)
		formData.append("wa_phone", this.state.wa_phone)
		formData.append("description", this.state.description)
		if (this.state.image) {
			formData.append("image", this.state.image[0])
		}

		this.props
			.updateResource(this.props.match.params.resourceId, formData)
			.then(() => {
				toast.success("Berhasil Update Resource")
				this.setState({
					readOnly: true,
				})

				this.getResource()
			})
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/resource'>Resources</Link>
							</BreadcrumbItem>

							<BreadcrumbItem>Tambah Resource</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Card>
						<CardBody>
							<h1 className='mb-4'>Detail Resource</h1>
							<Row>
								<Col xl={8}>
									<Form
										onSubmit={this.proxySubmit.bind(this)}
										id='create-resource'
									>
										<FormGroup>
											<Label for='name'>Nama Buku</Label>
											<Input
												readOnly={this.state.readOnly}
												type='text'
												name='title'
												id='title'
												placeholder='Masukan Nama Lengkap'
												onChange={(e) => {
													this.setState({
														title: e.target.value,
													})
												}}
												value={this.state.title}
											/>
										</FormGroup>

										<FormGroup>
											<Label for='price'>Harga</Label>
											<Row>
												<Col xl={10}>
													<Input
														id='price'
														className='form-control'
														type='number'
														onChange={(e) => {
															this.setState({
																price: e.target.value,
															})
														}}
														value={this.state.price}
														disabled={this.state.readOnly}
													/>
												</Col>
											</Row>
										</FormGroup>

										<Row>
											<Col xl={10}>
												<FormGroup>
													<Label for='description'>Deskripsi Buku</Label>
													<Input
														type='textarea'
														name='description'
														id='description'
														placeholder='Masukan Deskripsi'
														onChange={(e) => {
															this.setState({
																description: e.target.value,
															})
														}}
														value={this.state.description}
														readOnly={this.state.readOnly}
													/>
												</FormGroup>
											</Col>
										</Row>

										<FormGroup>
											<Label for='image'>Gambar</Label>
											<Row>
												<Col xl={10}>
													<Input
														className='form-control mb-2'
														type='file'
														onChange={(e) => {
															this.setState({
																image: e.target.files,
																showImage: URL.createObjectURL(
																	e.target.files[0]
																),
															})
														}}
														accept='image/png, image/gif, image/jpeg'
														disabled={this.state.readOnly}
													/>
												</Col>

												<Col xl={2}>
													<Button
														color='primary'
														type='button'
														onClick={this.modalToggle.bind(this)}
														disabled={!this.state.showImage}
													>
														Tampilkan
													</Button>
												</Col>
											</Row>

											<span className='mt-2'>
												dimensi gambar 16:9, ukuran gambar 5MB, format gambar
												(.jpg & .png)
											</span>
										</FormGroup>

										<FormGroup>
											<Label for='name'>No. Whatsapp</Label>
											<Input
												readOnly={this.state.readOnly}
												type='number'
												name='wa_phone'
												id='wa_phone'
												placeholder='Masukan No. Whatsapp'
												onChange={(e) => {
													this.setState({
														wa_phone: e.target.value,
													})
												}}
												value={this.state.wa_phone}
											/>
										</FormGroup>
									</Form>
								</Col>
							</Row>
							<Container className='d-flex'>
								<div className='d-flex flex-row align-items-center mb-3 ml-auto'>
									{this.state.readOnly ? (
										<>
											<Button
												color='primary'
												outline
												onClick={this.handleDelete.bind(this)}
											>
												Hapus
											</Button>
											<div>
												<Button
													color='primary'
													type='button'
													onClick={() => {
														this.setState({
															readOnly: !this.state.readOnly,
														})
													}}
												>
													Edit Resource
												</Button>
											</div>
										</>
									) : (
										<>
											<Button
												color='primary'
												outline
												onClick={this.goBack.bind(this)}
											>
												Batal
											</Button>
											<Button
												color='primary'
												type='submit'
												form='create-resource'
											>
												Simpan
											</Button>
										</>
									)}
								</div>
							</Container>
						</CardBody>
					</Card>
					<Modal
						toggle={this.modalToggle.bind(this)}
						isOpen={this.state.isModalShow}
					>
						<ModalHeader toggle={this.modalToggle.bind(this)}></ModalHeader>
						<ModalBody>
							<Container>
								<img src={this.state.showImage} width='100%' alt='' />
							</Container>
						</ModalBody>
					</Modal>
				</Container>
			</>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		resource: state.resource,
	}
}

const mapDispatchToProps = {
	getResource: resource.getResource,
	addResource: resource.addResource,
	updateResource: resource.updateResource,
	deleteResource: resource.deleteResource,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateResource)
