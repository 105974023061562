import { Component } from "react"
import {
	Container,
	Card,
	CardBody,
	CardTitle,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
} from "reactstrap"
import { Icon } from "@iconify/react"
import { instagram } from "../../redux/actions"

import { connect } from "react-redux"
import { toast } from "react-toastify"

class SpecialGuest extends Component {
	constructor() {
		super()
		this.state = {
			isModalShow: false,
			image: null,
		}
	}

	modalToggle() {
		this.setState({
			isModalShow: !this.state.isModalShow,
		})
	}

	changeIgProfile(e) {
		e.preventDefault()
		const formData = new FormData()

		formData.append("image", this.state.image)

		this.props.changeIgProfile(formData).then(() => {
			this.getIgProfile()
			toast.success("Berhasil Update Profile")
			this.modalToggle()
		})
	}

	getIgProfile() {
		this.props.getIgProfile()
	}

	componentDidMount() {
		this.getIgProfile()
	}

	render() {
		return (
			<Container>
				<Card className='card-stats mb-4 mb-xl-0'>
					<CardBody>
						<CardTitle g='h5' className='text-uppercase text-muted mb-0'>
							Instagram Photo Profile
						</CardTitle>
						<div className='d-flex justify-content-center'>
							<div className='img-content-slider'>
								<div
									className='img-content-slider'
									onClick={this.modalToggle.bind(this)}
									style={{
										backgroundImage: `url("${this.props.instagram.data}")`,
									}}
								>
									<div className='edit-fill'>
										<Icon icon='eva:edit-fill' />
									</div>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
				<Modal
					toggle={this.modalToggle.bind(this)}
					isOpen={this.state.isModalShow}
				>
					<ModalHeader
						toggle={this.modalToggle.bind(this)}
						cssModule={{
							"modal-title": "h3",
						}}
					>
						Change Profile Picture
					</ModalHeader>
					<ModalBody>
						<Container>
							<Form
								onSubmit={this.changeIgProfile.bind(this)}
								id='change-ig-profile'
							>
								<FormGroup>
									<Label for='image'>Profile Picture </Label>
									<Input
										className='form-control mb-2'
										id='image'
										name='image'
										type='file'
										onChange={(e) => {
											this.setState({
												image: e.target.files[0],
											})
										}}
									/>
								</FormGroup>

								<div className='d-flex justify-content-end'>
									<Button
										color='primary'
										outline
										onClick={this.modalToggle.bind(this)}
										type='button'
									>
										Batal
									</Button>
									<Button
										color='primary'
										type='submit'
										form='change-ig-profile'
									>
										Simpan
									</Button>
								</div>
							</Form>
						</Container>
					</ModalBody>
				</Modal>
			</Container>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		instagram: state.instagram,
	}
}

const mapDispatchToProps = {
	getIgProfile: instagram.getIgProfile,
	changeIgProfile: instagram.changeIgProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialGuest)
