import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Breadcrumb,
	BreadcrumbItem,
} from "reactstrap"
import CreatableSelect from "react-select/creatable"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { connect } from "react-redux"
import { church, event } from "../../../redux/actions"
import Swal from "sweetalert2"
class CreateEventSchedule extends Component {
	constructor(props) {
		super(props)

		this.state = {
			date: null,
			dateDisabled: false,
			time: null,
			schedules: [],

			name: "",
			churchId: null,
			placeValue: null,
			address: "",
			capacity: "",
			image: null,
			place_name: null,
			isModalShow: false,
			showImage: null,

			readOnly: this.props.match.params.eventId !== "create",

			editMode: false,
		}
	}

	modalToggle() {
		this.setState({
			isModalShow: !this.state.isModalShow,
		})
	}

	goBack() {
		if (this.props.match.params.eventId === "create") {
			this.props.history.push("/admin/schedule/event")
		}

		return this.setState({
			readOnly: !this.state.readOnly,
		})
	}

	addSchedules() {
		if (!this.state.date) {
			return toast.error("Mohon isi Tanggal")
		}

		if (!this.state.time) {
			return toast.error("Mohon isi Waktu")
		}

		return this.setState({
			schedules: this.state.schedules.concat([this.state.time]),
			time: "",
			dateDisabled: true,
		})
	}

	deleteSchedule(key) {
		const finder = this.state.schedules.filter((value, index) => index !== key)

		this.setState({
			schedules: finder,
			dateDisabled: finder.length !== 0,
		})
	}

	addEvent() {
		this.props
			.addEvent({
				name: this.state.name,
				place_name: this.state.place_name,
				capacity: this.state.capacity,
				image: this.state.image,
				church_id: this.state.churchId,
				address: this.state.address,
				description: "-",
				type: "2",
				schedules: [
					{
						date: this.state.date,
						time: this.state.schedules,
					},
				],
			})
			.then(() => {
				toast.success("Berhasil Tambah Event")
				this.props.history.push("/admin/schedule/event")
			})
	}

	getChurches() {
		this.props.getChurches(this.state.params)
	}

	getEvent() {
		this.props.getEvent(this.props.match.params.eventId).then(() => {
			const detail = this.props.event.detail
			this.setState({
				date: detail.date,
				dateDisabled: true,
				time: null,
				schedules: detail.EventDateTimes.map((dateTime) => {
					return dateTime.time
				}),

				name: detail.Event.name,
				churchId: detail.Event.churchId,
				placeValue: {
					label: detail.Event.place_name,
					value: detail.Event.churchId
						? detail.Event.churchId
						: detail.Event.place_name,
				},
				address: detail.Event.address,
				capacity: detail.Event.capacity,
				place_name: detail.Event.place_name,
				isModalShow: false,
				showImage: detail.image,
			})
		})
	}

	handleDelete() {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.deleteEvent(this.props.match.params.eventId).then(() => {
					Swal.fire("Deleted!", "Schedule has been deleted.", "success")
					this.props.history.push(`/admin/schedule/event`)
				})
			}
		})
	}

	componentDidMount() {
		this.getChurches()
		if (this.state.readOnly) {
			this.getEvent()
		}
	}

	get places() {
		return this.props.church.data.map((church) => {
			return {
				label: church.name,
				value: church.id,
				data: church,
			}
		})
	}

	updateEvent() {
		this.props
			.updateEvent(this.props.match.params.eventId, {
				name: this.state.name,
				place_name: this.state.place_name,
				capacity: String(this.state.capacity),
				image: this.state.image ? this.state.image : undefined,
				church_id: this.state.churchId,
				address: this.state.address,
				type: "2",
				schedules: [
					{
						date: this.state.date,
						time: this.state.schedules,
					},
				],
			})
			.then(() => {
				toast.success("Berhasil Edit Event")
				this.getEvent()

				this.setState({
					readOnly: true,
				})
			})
	}

	proxySubmit(e) {
		e.preventDefault()

		if (this.props.match.params.eventId === "create") {
			return this.addEvent()
		}

		return this.updateEvent()
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/schedule/event'>Schedule</Link>
							</BreadcrumbItem>
							<BreadcrumbItem>
								<Link to='/admin/schedule/event'>Event</Link>
							</BreadcrumbItem>

							<BreadcrumbItem>Tambah Schedule</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Card>
						<CardBody>
							<h1 className='mb-4'>Detail Event</h1>
							<Row>
								<Col xl={8}>
									<Form
										onSubmit={this.proxySubmit.bind(this)}
										id='create-event'
									>
										<FormGroup>
											<Label for='schedule'>Nama Event</Label>
											<Input
												readOnly={this.state.readOnly}
												type='text'
												name='schedul:e'
												id='schedule'
												placeholder='Masukan Nama Event'
												onChange={(e) => {
													this.setState({
														name: e.target.value,
													})
												}}
												value={this.state.name}
											/>
										</FormGroup>

										<FormGroup>
											<Label for='exampleSelect'>Tempat</Label>
											<CreatableSelect
												isClearable
												isDisabled={this.state.readOnly}
												onChange={(e) => {
													if (e["__isNew__"]) {
														this.setState({
															placeValue: e,
															churchId: null,
															place_name: e.label,
														})
													} else {
														this.setState({
															placeValue: e,
															churchId: e.value,
															place_name: e.label,
															address: e.data.address,
															capacity: e.data.capacity,
														})
													}
												}}
												options={this.places}
												placeholder='Pilih atau ketik nama tempat'
												value={this.state.placeValue}
											/>
										</FormGroup>

										<Row>
											<Col xl={10}>
												<FormGroup>
													<Label for='address'>Alamat</Label>
													<Input
														type='textarea'
														name='address'
														id='address'
														placeholder='Masukan Alamat Lengkap'
														onChange={(e) => {
															this.setState({
																address: e.target.value,
															})
														}}
														value={this.state.address}
														readOnly={this.state.readOnly}
													/>
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col xl={10}>
												<FormGroup>
													<Label for='address'>Kapasitas</Label>
													<Input
														type='number'
														name='capacity'
														id='capacity'
														placeholder='Kapasitas Acara'
														onChange={(e) => {
															this.setState({
																capacity: e.target.value,
															})
														}}
														value={this.state.capacity}
														readOnly={this.state.readOnly}
													/>
												</FormGroup>
											</Col>
										</Row>

										<FormGroup>
											<Label for='image'>Gambar</Label>
											<Row>
												<Col xl={10}>
													<Input
														className='form-control mb-2'
														type='file'
														onChange={(e) => {
															let reader = new FileReader()
															reader.readAsDataURL(e.target.files[0])
															reader.onload = () => {
																this.setState({
																	image: reader.result,
																	showImage: URL.createObjectURL(
																		e.target.files[0]
																	),
																})
															}
															reader.onerror = function (error) {
																console.log("Error: ", error)
															}
														}}
														accept='image/png, image/gif, image/jpeg'
														disabled={this.state.readOnly}
													/>
												</Col>

												<Col xl={2}>
													<Button
														color='primary'
														type='button'
														onClick={this.modalToggle.bind(this)}
														disabled={!this.state.showImage}
													>
														Tampilkan
													</Button>
												</Col>
											</Row>

											<span className='mt-2'>
												dimensi gambar 16:9, ukuran gambar 5MB, format gambar
												(.jpg & .png)
											</span>
										</FormGroup>
										<hr />

										<h1 className='mb-4'>Tambah Schedule</h1>

										<FormGroup>
											<Label for='date'>Pilih Tanggal</Label>
											<Row>
												<Col xl={10}>
													<Input
														type='date'
														name='date'
														id='date'
														disabled={this.state.dateDisabled}
														onChange={(e) =>
															this.setState({
																date: e.target.value,
															})
														}
														value={this.state.date}
														readOnly={this.state.readOnly}
													/>
												</Col>
											</Row>
											<span>
												Hanya dapat memilih satu tanggal saat membuat schedule
											</span>
										</FormGroup>

										<FormGroup>
											<Label for='time'>Pilih Waktu</Label>
											<Row>
												<Col xl={10}>
													<Input
														type='time'
														name='time'
														id='time'
														onChange={(e) =>
															this.setState({
																time: e.target.value,
															})
														}
														value={this.state.time}
														readOnly={this.state.readOnly}
													/>
												</Col>
												<Col xl={2}>
													<Button
														color='primary'
														type='button'
														onClick={this.addSchedules.bind(this)}
														disabled={this.state.readOnly}
													>
														Tambah
													</Button>
												</Col>
											</Row>
										</FormGroup>

										<hr />

										<h1 className='mb-4'>Waktu yang dipilih</h1>

										<div className='d-flex flex-column'>
											{this.state.schedules.map((time, i) => (
												<div className='d-flex flex-row align-items-center mb-3'>
													<div className='time-badge mr-3'>{time}</div>
													<button
														type='button'
														className='btn-link'
														onClick={() => {
															this.deleteSchedule(i)
														}}
														disabled={this.state.readOnly}
													>
														Hapus
													</button>
												</div>
											))}
										</div>
									</Form>
								</Col>
							</Row>
							<Container className='d-flex'>
								<div className='d-flex flex-row align-items-center mb-3 ml-auto'>
									{this.state.readOnly ? (
										<>
											<Button
												color='primary'
												outline
												onClick={this.handleDelete.bind(this)}
											>
												Hapus
											</Button>
											<div>
												<Button
													color='primary'
													type='button'
													onClick={() => {
														this.setState({
															readOnly: !this.state.readOnly,
														})
													}}
												>
													Edit Schedule
												</Button>
											</div>
										</>
									) : (
										<>
											<Button
												color='primary'
												outline
												onClick={this.goBack.bind(this)}
											>
												Batal
											</Button>
											<Button color='primary' type='submit' form='create-event'>
												Simpan
											</Button>
										</>
									)}
								</div>
							</Container>
						</CardBody>
					</Card>
					<Modal
						toggle={this.modalToggle.bind(this)}
						isOpen={this.state.isModalShow}
					>
						<ModalHeader toggle={this.modalToggle.bind(this)}></ModalHeader>
						<ModalBody>
							<Container>
								<img src={this.state.showImage} width='100%' alt='' />
							</Container>
						</ModalBody>
					</Modal>
				</Container>
			</>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		church: state.church,
		event: state.event,
	}
}

const mapDispatchToProps = {
	getChurches: church.getChurches,
	addEvent: event.addEvent,
	getEvent: event.getEvent,
	deleteEvent: event.deleteEvent,
	updateEvent: event.updateEvent,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateEventSchedule)
