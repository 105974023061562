import { Component } from "react"
import {
	Container,
	Card,
	CardBody,
	CardTitle,
	Modal,
	ModalHeader,
	ModalBody,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
} from "reactstrap"
import { Icon } from "@iconify/react"
import { connect } from "react-redux"
import { magazine } from "../../redux/actions"
import Joi from "joi"
import { toast } from "react-toastify"

class WeeklyMagazine extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isModalShow: false,
			iframe: {
				value: "",
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			image: null,
		}
	}

	modalToggle() {
		this.setState({
			isModalShow: !this.state.isModalShow,
		})
	}
	changeMagazine(e) {
		e.preventDefault()
		const formData = new FormData()
		formData.append("iframe", this.state.iframe.value)
		formData.append("image", this.state.image)

		this.props.changeMagazine(formData).then(() => {
			this.getLastMagazine()
			toast.success("Berhasil Update Magazine")
			this.modalToggle()
		})
	}

	inputOnChange(name, value) {
		const fieldProperties = { ...this.state }
		const isValid = fieldProperties[name].rule.validate(value)

		fieldProperties[name].valid = !isValid.error
		fieldProperties[name].value = value

		if (isValid.error) {
			fieldProperties[name].errMsg = isValid.error.message
		}

		this.setState(fieldProperties)
	}

	getLastMagazine() {
		this.props.getLastMagazine()
	}

	componentDidMount() {
		this.getLastMagazine()
	}

	render() {
		return (
			<Container>
				<Card className='card-stats mb-4 mb-xl-0'>
					<CardBody>
						<CardTitle g='h5' className='text-uppercase text-muted mb-0'>
							Weekly Magazine
						</CardTitle>
						<div className='d-flex justify-content-center'>
							<div
								className='img-content-slider'
								onClick={this.modalToggle.bind(this)}
								style={{
									backgroundImage: `url("${this.props.magazine.data.cover_image}")`,
								}}
							>
								<div className='edit-fill'>
									<Icon icon='eva:edit-fill' />
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
				<Modal
					toggle={this.modalToggle.bind(this)}
					isOpen={this.state.isModalShow}
				>
					<ModalHeader
						toggle={this.modalToggle.bind(this)}
						cssModule={{
							"modal-title": "h3",
						}}
					>
						Update Magazine
					</ModalHeader>
					<ModalBody>
						<Container>
							<Form
								onSubmit={this.changeMagazine.bind(this)}
								id='update-magazine'
							>
								<FormGroup>
									<Label for='iframe'>Iframe</Label>
									<Input
										id='iframe'
										name='iframe'
										type='textarea'
										placeholder='Masukan iframe'
										onChange={(e) => {
											this.inputOnChange("iframe", e.target.value)
										}}
										value={this.state.iframe.value}
									/>
								</FormGroup>

								<FormGroup>
									<Label for='image'>Thumbnail </Label>
									<Input
										className='form-control mb-2'
										id='image'
										name='image'
										type='file'
										onChange={(e) => {
											this.setState({
												image: e.target.files[0],
											})
										}}
									/>

									<span className='mt-2'>
										dimensi gambar 1:2, ukuran gambar 5MB, format gambar (.jpg &
										.png)
									</span>
								</FormGroup>

								<div className='d-flex justify-content-end'>
									<Button
										color='primary'
										outline
										onClick={this.modalToggle.bind(this)}
										type='button'
									>
										Batal
									</Button>
									<Button color='primary' type='submit' form='update-magazine'>
										Simpan
									</Button>
								</div>
							</Form>
						</Container>
					</ModalBody>
				</Modal>
			</Container>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		magazine: state.magazine,
	}
}

const mapDispatchToProps = {
	getLastMagazine: magazine.getLastMagazine,
	changeMagazine: magazine.changeMagazine,
}
export default connect(mapStateToProps, mapDispatchToProps)(WeeklyMagazine)
