import { promiseState } from "../../helper"
import { banner } from "../../redux/actions/types"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	errors: [],
	message: "",
	eventData: [],
	infoData: [],
}

function bannerReducer(state = initialState, action) {
	switch (action.type) {
		case pendingState(banner.GET_BANNER): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(banner.GET_BANNER): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(banner.GET_BANNER): {
			return Object.assign({}, state, {
				isLoading: false,
				infoData: action.payload.data.data,
			})
		}

		case pendingState(banner.GET_EVENT_BANNER): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}

		case rejectedState(banner.GET_EVENT_BANNER): {
			return Object.assign({}, state, {
				isLoading: false,
			})
		}

		case fulfilledState(banner.GET_EVENT_BANNER): {
			return Object.assign({}, state, {
				isLoading: false,
				eventData: action.payload.data.data,
			})
		}

		default:
			return state
	}
}

export default bannerReducer
