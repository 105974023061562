import React from "react"
import { useLocation, Route, Switch, Redirect } from "react-router-dom"
// core components
import AdminNavbar from "../components/Navbars/AdminGlowNavbar"
import Sidebar from "../components/Sidebar/Sidebar.js"

import routes from "../routes.js"

const Admin = (props) => {
	const mainContent = React.useRef(null)
	const location = useLocation()

	React.useEffect(() => {
		document.documentElement.scrollTop = 0
		document.scrollingElement.scrollTop = 0
		mainContent.current.scrollTop = 0
	}, [location])

	const getRoutes = (routes) => {
		// eslint-disable-next-line array-callback-return
		return routes.map((prop, key) => {
			if (prop.layout === "/admin") {
				if (prop.collapse) {
					prop.collapse_item.map((child_prop, child_key) => {
						return (
							<Route
								path={prop.layout + prop.path + child_prop.path}
								exact
								component={child_prop.component}
								key={`${prop.key}child${child_key}`}
							/>
						)
					})
				} else {
					return (
						<Route
							path={prop.layout + prop.path}
							component={prop.component}
							exact
							key={key}
						/>
					)
				}
			}
		})
	}

	const getBrandText = (path) => {
		for (let i = 0; i < routes.length; i++) {
			if (
				props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
				-1
			) {
				return routes[i].name
			}
		}
		return "Brand"
	}

	return (
		<>
			<Sidebar
				{...props}
				routes={routes}
				logo={{
					innerLink: "/admin/index",
					imgSrc: require("../assets/glowfc/img/glowfc-logo.png").default,
					imgAlt: "...",
				}}
			/>
			<div className='main-content' ref={mainContent}>
				<AdminNavbar
					{...props}
					brandText={getBrandText(props.location.pathname)}
				/>
				<Switch>
					{getRoutes(routes)}
					<Redirect from='*' to='/admin/index' />
				</Switch>
			</div>
		</>
	)
}

export default Admin
