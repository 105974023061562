import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Button,
	Breadcrumb,
	BreadcrumbItem,
	FormFeedback,
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"
import Joi from "joi"
import { church } from "../../../redux/actions"
import { connect } from "react-redux"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

class CreateChurch extends Component {
	constructor(props) {
		super(props)

		this.state = {
			name: {
				value: "",
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			address: {
				value: "",
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			description: {
				value: "",
				rule: Joi.string().required(),
				valid: true,
				errMsg: "",
			},

			capacity: {
				value: "",
				rule: Joi.string().alphanum().required(),
				valid: true,
				errMsg: "",
			},

			image: {
				value: null,
				valid: true,
				errMsg: "",
				rule: {
					validate(param) {
						return {
							value: param,
						}
					},
				},
			},

			churchId: this.props.match.params.churchId,

			isModalShow: false,
			disable: this.props.match.params.churchId !== "create",
			showImage: null,
		}
	}

	modalToggle() {
		this.setState({
			isModalShow: !this.state.isModalShow,
		})
	}

	inputOnChange(name, value) {
		const fieldProperties = { ...this.state }
		const isValid = fieldProperties[name].rule.validate(value)

		fieldProperties[name].valid = !isValid.error
		fieldProperties[name].value = value

		if (isValid.error) {
			fieldProperties[name].errMsg = isValid.error.message
		}

		this.setState(fieldProperties)
	}

	goBack() {
		if (this.props.match.params.churchId === "create") {
			this.props.history.push("/admin/church")
		}

		return this.setState({
			disable: !this.state.disable,
		})
	}

	proxySubmit(e) {
		e.preventDefault()

		if (this.state.churchId === "create") {
			return this.addChurch()
		} else {
			return this.updateChurch()
		}
	}

	handleDelete() {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.deleteChurch(this.props.match.params.churchId).then(() => {
					Swal.fire("Deleted!", "Church has been deleted.", "success")
					this.props.history.push(`/admin/church`)
				})
			}
		})
	}

	updateChurch() {
		const formData = new FormData()

		formData.append("name", this.state.name.value)
		formData.append("description", this.state.description.value)
		formData.append("capacity", this.state.capacity.value)
		formData.append("address", this.state.address.value)

		if (this.state.image.value) {
			formData.append("image", this.state.image.value[0])
		}

		this.props
			.updateChurch(this.props.match.params.churchId, formData)
			.then(() => {
				toast.success("Berhasil Edit Gereja")
				this.getChurch()

				this.setState({
					disable: true,
				})
			})
	}

	addChurch() {
		const formData = new FormData()

		formData.append("name", this.state.name.value)
		formData.append("description", this.state.description.value)
		formData.append("capacity", this.state.capacity.value)
		formData.append("address", this.state.address.value)
		formData.append("image", this.state.image.value[0])
		this.props.addChurch(formData).then(() => {
			toast.success("Tambah Gereja Berhasil")

			this.props.history.push("/admin/church")
		})
	}

	getChurch() {
		this.props.getChurch(this.props.match.params.churchId).then(() => {
			const fieldProperties = { ...this.state }
			fieldProperties.name.value = this.props.church.detail.name
			fieldProperties.address.value = this.props.church.detail.address
			fieldProperties.capacity.value = this.props.church.detail.capacity
			fieldProperties.description.value = this.props.church.detail.description
			fieldProperties.showImage = this.props.church.detail.image

			this.setState(fieldProperties)
		})
	}

	componentDidMount() {
		if (this.props.match.params.churchId !== "create") {
			this.getChurch()
		}
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/church'>Data Gereja</Link>
							</BreadcrumbItem>
							<BreadcrumbItem active>Tambah Data</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<Col xl={12}>
							<Card>
								<CardBody>
									<h1 className='mb-4'>Detail Gereja</h1>
									<Row>
										<Col xl={8}>
											<Form
												onSubmit={this.proxySubmit.bind(this)}
												id='create-church-form'
											>
												<FormGroup>
													<Label for='name'>Nama Gereja</Label>
													<Input
														type='text'
														name='name'
														id='name'
														placeholder='Masukan Nama Gereja'
														onChange={(e) =>
															this.inputOnChange("name", e.target.value)
														}
														invalid={!this.state.name.valid}
														value={this.state.name.value}
														disabled={this.state.disable}
													/>
													<FormFeedback>{this.state.name.errMsg}</FormFeedback>
												</FormGroup>
												<FormGroup>
													<Label for='address'>Alamat Gereja</Label>
													<Row>
														<Col xl={10}>
															<Input
																type='textarea'
																name='address'
																id='address'
																placeholder='Masukan Alamat Lengkap'
																onChange={(e) =>
																	this.inputOnChange("address", e.target.value)
																}
																invalid={!this.state.address.valid}
																value={this.state.address.value}
																disabled={this.state.disable}
															/>
															<FormFeedback>
																{this.state.address.errMsg}
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>

												<Row>
													<Col xl={4}>
														<FormGroup>
															<Label for='capacity'>Kapasitas</Label>
															<Input
																type='number'
																name='capacity'
																id='capacity'
																placeholder='Masukan Kapasitas'
																onChange={(e) =>
																	this.inputOnChange("capacity", e.target.value)
																}
																invalid={!this.state.capacity.valid}
																value={this.state.capacity.value}
																disabled={this.state.disable}
															/>
															<FormFeedback>
																{this.state.capacity.errMsg}
															</FormFeedback>
														</FormGroup>
													</Col>
												</Row>

												<FormGroup>
													<Label for='description'>Deskripsi</Label>
													<Row>
														<Col xl={10}>
															<Input
																id='description'
																name='description'
																type='textarea'
																placeholder='Masukan Deskripsi'
																onChange={(e) =>
																	this.inputOnChange(
																		"description",
																		e.target.value
																	)
																}
																invalid={!this.state.description.valid}
																value={this.state.description.value}
																disabled={this.state.disable}
															/>
															<FormFeedback>
																{this.state.description.errMsg}
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>

												<FormGroup>
													<Label for='image'>Gambar</Label>
													<Row>
														<Col xl={10}>
															<Input
																className='form-control mb-2'
																id='image'
																name='image'
																type='file'
																onChange={(e) => {
																	this.inputOnChange("image", e.target.files)
																	this.setState({
																		showImage: e.target.files[0],
																	})
																}}
																accept='image/png, image/gif, image/jpeg'
																disabled={this.state.disable}
															/>
														</Col>

														<Col xl={2}>
															<Button
																color='primary'
																type='button'
																onClick={this.modalToggle.bind(this)}
																disabled={!this.state.showImage}
															>
																Tampilkan
															</Button>
														</Col>
													</Row>

													<span className='mt-2'>
														dimensi gambar 1:2, ukuran gambar 5MB, format gambar
														(.jpg & .png)
													</span>
												</FormGroup>
											</Form>
										</Col>
									</Row>
									<Container className='d-flex'>
										<div className='d-flex flex-row align-items-center mb-3 ml-auto'>
											{this.state.disable ? (
												<>
													<Button
														color='primary'
														outline
														onClick={this.handleDelete.bind(this)}
													>
														Hapus
													</Button>
													<div>
														<Button
															color='primary'
															type='button'
															onClick={() => {
																this.setState({
																	disable: !this.state.disable,
																})
															}}
														>
															Edit Gereja
														</Button>
													</div>
												</>
											) : (
												<>
													<Button
														color='primary'
														outline
														onClick={this.goBack.bind(this)}
													>
														Batal
													</Button>
													<Button
														color='primary'
														type='submit'
														form='create-church-form'
													>
														Simpan
													</Button>
												</>
											)}
										</div>
									</Container>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Modal
						toggle={this.modalToggle.bind(this)}
						isOpen={this.state.isModalShow}
					>
						<ModalHeader toggle={this.modalToggle.bind(this)}></ModalHeader>
						<ModalBody>
							<Container>
								<img src={this.state.showImage} width='100%' alt='' />
							</Container>
						</ModalBody>
					</Modal>
				</Container>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		church: state.church,
	}
}

const mapDispatchToProps = {
	addChurch: church.addChurch,
	updateChurch: church.updateChurch,
	getChurch: church.getChurch,
	deleteChurch: church.deleteChurch,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateChurch)
