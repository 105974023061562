import { Component } from "react"
import { connect } from "react-redux"
import { Redirect, Route } from "react-router-dom"
class ProtectedRoute extends Component {
	render() {
		if (!this.props.auth.data.token) {
			return <Redirect to='/auth/login' />
		}

		return <Route {...this.props} />
	}
}

const mapStateToProps = (state) => {
	return { auth: state.auth }
}

export default connect(mapStateToProps)(ProtectedRoute)
