import { Icon } from "@iconify/react"
import Index from "./views/Dashboard"
import { Account, CreateAccount } from "./views/glowfc/account"
import Church from "./views/glowfc/church/Church"
import CreateChurch from "./views/glowfc/church/CreateChurch"
import User from "./views/glowfc/user/User"
import DetailUser from "./views/glowfc/user/DetailUser"
import Schedule from "./views/glowfc/schedule/Schedule"
import ScheduleEvent from "./views/glowfc/schedule/ScheduleEvent"
import Worship from "./views/glowfc/schedule/Worship"
import RedirectSchedule from "./views/glowfc/schedule/RedirectSchedule"
import CreateEventSchedule from "./views/glowfc/schedule/CreateEventSchedule"
import CreateWorshipSchedule from "./views/glowfc/schedule/CreateWorshipSchedule"
import Login from "./views/glowfc/auth/Login"
import { StreamingVideo, CreateVideo } from "./views/glowfc/streaming"
import Banner from "./views/glowfc/banner/Banner"
import CreateBanner from "./views/glowfc/banner/CreateBanner"
import {
	Sacrament,
	Baptism,
	HolyMatrimony,
	ChildSubmission,
	BaptismDetail,
	ChildSubmissionDetail,
	MatrimonialDetail,
} from "./views/glowfc/sacrament"
import { Resource, CreateResource } from "./views/glowfc/resource"
import { Secretariat, CreateSecretariat } from "./views/glowfc/secretariat"
import { Ministry, UpdateMinistryContent } from "./views/glowfc/ministry"
import { ChurchRecord, DetailRecord } from "./views/glowfc/church-record"

var routes = [
	{
		path: "/index",
		name: "Dashboard",
		icon: (
			<Icon
				icon='ic:baseline-space-dashboard'
				className='mr-3'
				fontSize={"18px"}
			/>
		),
		component: Index,
		layout: "/admin",
	},

	{
		path: "/schedule",
		name: "Schedule",
		icon: (
			<Icon icon='bi:calendar-check-fill' className='mr-3' fontSize={"18px"} />
		),
		component: RedirectSchedule,
		layout: "/admin",
	},

	{
		path: "/schedule/worship",
		sidebarHide: true,
		name: "Schedule",
		icon: (
			<Icon icon='bi:calendar-check-fill' className='mr-3' fontSize={"18px"} />
		),
		component: Schedule,
		layout: "/admin",
	},

	{
		path: "/schedule/worship/:churchId",
		sidebarHide: true,
		name: "Schedule",
		icon: (
			<Icon icon='bi:calendar-check-fill' className='mr-3' fontSize={"18px"} />
		),
		component: Worship,
		layout: "/admin",
	},

	{
		path: "/schedule/worship/:churchId/:timeId",
		sidebarHide: true,
		name: "Schedule",
		icon: (
			<Icon icon='bi:calendar-check-fill' className='mr-3' fontSize={"18px"} />
		),
		component: CreateWorshipSchedule,
		layout: "/admin",
	},

	{
		path: "/schedule/event",
		name: "Schedule Event",
		icon: (
			<Icon icon='bi:calendar-check-fill' className='mr-3' fontSize={"18px"} />
		),
		component: ScheduleEvent,
		layout: "/admin",
		sidebarHide: true,
	},

	{
		path: "/schedule/event/:eventId",
		sidebarHide: true,
		icon: (
			<Icon icon='bi:calendar-check-fill' className='mr-3' fontSize={"18px"} />
		),
		component: CreateEventSchedule,
		layout: "/admin",
	},

	{
		path: "/sacrament",
		name: "Sakramen",
		icon: (
			<Icon icon='ant-design:file-filled' className='mr-3' fontSize={"18px"} />
		),
		component: Sacrament,
		layout: "/admin",
	},

	{
		path: "/sacrament/baptism",
		sidebarHide: true,
		component: Baptism,
		layout: "/admin",
	},

	{
		path: "/sacrament/baptism/:baptismId",
		sidebarHide: true,
		component: BaptismDetail,
		layout: "/admin",
	},

	{
		path: "/sacrament/holy-matrimony",
		sidebarHide: true,
		component: HolyMatrimony,
		layout: "/admin",
	},

	{
		path: "/sacrament/holy-matrimony/:matrimonyId",
		sidebarHide: true,
		component: MatrimonialDetail,
		layout: "/admin",
	},

	{
		path: "/sacrament/child-submission",
		sidebarHide: true,
		component: ChildSubmission,
		layout: "/admin",
	},
	{
		path: "/sacrament/child-submission/:childId",
		sidebarHide: true,
		component: ChildSubmissionDetail,
		layout: "/admin",
	},

	{
		path: "/banner",
		name: "Banner & Informasi",
		icon: (
			<Icon icon='akar-icons:newspaper' className='mr-3' fontSize={"18px"} />
		),
		component: Banner,
		layout: "/admin",
	},

	{
		path: "/banner/create",
		sidebarHide: true,
		component: CreateBanner,
		layout: "/admin",
	},

	{
		path: "/resource",
		name: "Resource",
		icon: <Icon icon='bxs:book-alt' className='mr-3' fontSize={"18px"} />,
		component: Resource,
		layout: "/admin",
	},

	{
		path: "/resource/:resourceId",
		sidebarHide: true,
		component: CreateResource,
		layout: "/admin",
	},

	{
		path: "/secretariat",
		name: "Sekretariat",
		icon: (
			<Icon icon='dashicons:businessman' className='mr-3' fontSize={"18px"} />
		),
		component: Secretariat,
		layout: "/admin",
	},

	{
		path: "/secretariat/:secretariatId",
		sidebarHide: true,
		component: CreateSecretariat,
		layout: "/admin",
	},

	{
		path: "/video-resource",
		name: "Video Resources",
		icon: (
			<Icon icon='entypo:folder-video' className='mr-3' fontSize={"18px"} />
		),
		component: StreamingVideo,
		layout: "/admin",
	},

	{
		path: "/video-resource/:videoId",
		sidebarHide: true,
		component: CreateVideo,
		layout: "/admin",
	},

	{
		path: "/ministry",
		name: "Ministry",
		icon: (
			<Icon
				icon='medical-icon:social-services'
				className='mr-3'
				fontSize={"18px"}
			/>
		),
		component: Ministry,
		layout: "/admin",
	},

	{
		path: "/ministry/:categoryId/content",
		sidebarHide: true,
		component: UpdateMinistryContent,
		layout: "/admin",
	},

	// {
	// 	path: "/donation",
	// 	name: "Support & Give",
	// 	icon: <Icon icon='ri:service-fill' className='mr-3' fontSize={"18px"} />,
	// 	component: NotFound,
	// 	layout: "/admin",
	// },

	{
		path: "/user",
		name: "Data User",
		icon: (
			<Icon icon='fa-solid:user-friends' className='mr-3' fontSize={"18px"} />
		),
		component: User,
		layout: "/admin",
	},

	{
		path: "/user/:userId",
		name: "Data User",
		sidebarHide: true,
		component: DetailUser,
		layout: "/admin",
	},

	{
		path: "/church-record",
		name: "Pendataan Jemaat",
		icon: (
			<Icon
				icon='fluent:people-edit-20-filled'
				className='mr-3'
				fontSize={"18px"}
			/>
		),
		component: ChurchRecord,
		layout: "/admin",
	},

	{
		path: "/church-record/:recordId",
		sidebarHide: true,
		component: DetailRecord,
		layout: "/admin",
	},

	{
		path: "/account",
		name: "Manajemen Akun",
		icon: <Icon icon='mdi:account-cog' className='mr-3' fontSize={"18px"} />,
		component: Account,
		layout: "/admin",
	},

	{
		path: "/account/:userId",
		icon: <i className='fas fa-user'></i>,
		component: CreateAccount,
		layout: "/admin",
		sidebarHide: true,
	},

	{
		path: "/church",
		name: "Data Gereja",
		icon: <Icon icon='fa6-solid:database' className='mr-3' fontSize={"18px"} />,
		component: Church,
		layout: "/admin",
	},

	{
		path: "/church/:churchId",
		icon: <i className='fas fa-user'></i>,
		component: CreateChurch,
		layout: "/admin",
		sidebarHide: true,
	},

	{
		path: "/login",
		name: "Login",
		icon: "ni ni-key-25 text-info",
		component: Login,
		layout: "/auth",
	},
]
export default routes
