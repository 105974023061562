import {
	Card,
	CardBody,
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Button,
	Breadcrumb,
	BreadcrumbItem,
} from "reactstrap"
// core components
import { Component } from "react"
import { Link } from "react-router-dom"

import { connect } from "react-redux"
import { banner } from "../../../redux/actions"

import { toast } from "react-toastify"

class CreateWorshipSchedule extends Component {
	constructor(props) {
		super(props)

		this.state = {
			image: null,
			type: null,
		}
	}

	get data() {
		const formData = new FormData()

		formData.append("image", this.state.image)

		return formData
	}

	componentDidMount() {}

	eventSubmit() {
		this.props.addEventBanner(this.data).then(() => {
			toast.success("Tambah Banner Event Berhasil")
			this.props.history.push("/admin/banner")
		})
	}

	infoSubmit() {
		this.props.addInfoBanner(this.data).then(() => {
			toast.success("Tambah Banner Informasi Berhasil")
			this.props.history.push("/admin/banner")
		})
	}

	proxySubmit(e) {
		e.preventDefault()

		if (!this.state.image) {
			return toast.error("Gambar Belum diisi")
		}

		if (this.state.type === "event") {
			return this.eventSubmit()
		}

		if (this.state.type === "info") {
			return this.infoSubmit()
		}

		return toast.error("Pilih Jenis Konten")
	}

	render() {
		return (
			<>
				{/* Page content */}
				<Container fluid className='mb-5'>
					<div>
						<Breadcrumb>
							<BreadcrumbItem>
								<Link to='/admin/banner'>Banner & Informasi</Link>
							</BreadcrumbItem>

							<BreadcrumbItem>Tambah Banner & Informasi</BreadcrumbItem>
						</Breadcrumb>
					</div>
					{/* Table */}
					<Row>
						<Col xl={7}>
							<Card>
								<CardBody>
									<h1 className='mb-4'>Tambah Banner & Informasi</h1>
									<Row>
										<Container>
											<Form
												onSubmit={this.proxySubmit.bind(this)}
												id='create-banner'
											>
												<FormGroup>
													<Label for='name'>Jenis Konten</Label>
													<Input
														type='select'
														onChange={(e) =>
															this.setState({ type: e.target.value })
														}
													>
														<option value='' selected disabled>
															Pilih Salah Satu
														</option>
														<option value='event'>Event</option>
														<option value='info'>Informasi</option>
													</Input>
												</FormGroup>

												<FormGroup>
													<Label for='image'>Gambar Konten</Label>
													<Input
														type='file'
														className='form-control'
														accept='image/png, image/gif, image/jpeg'
														onChange={(e) => {
															this.setState({ image: e.target.files[0] })
														}}
													/>
													<span>
														dimensi gambar 16:9, ukuran gambar 5MB, format
														gambar (.jpg & .png)
													</span>
												</FormGroup>
											</Form>
										</Container>
									</Row>
									<div className='d-flex justify-content-end'>
										{this.state.disable ? (
											<>
												<Button
													color='primary'
													outline
													onClick={this.handleDelete.bind(this)}
												>
													Hapus
												</Button>
												<Button
													color='primary'
													type='button'
													onClick={() => {
														this.setState({
															disable: !this.state.disable,
														})
													}}
												>
													Edit Schedule
												</Button>
											</>
										) : (
											<>
												<Button
													color='primary'
													outline
													onClick={() => {
														this.props.history.push("/admin/banner")
													}}
												>
													Batal
												</Button>
												<Button
													color='primary'
													type='submit'
													form='create-banner'
												>
													Simpan
												</Button>
											</>
										)}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

const mapDispatchToProps = {
	addEventBanner: banner.addEventBanner,
	addInfoBanner: banner.addInfoBanner,
}

export default connect(null, mapDispatchToProps)(CreateWorshipSchedule)
