import getEventBanner from "./getEventBanner"
import getInfoBanner from "./getInfoBanner"
import addEventBanner from "./addEventBanner"
import addInfoBanner from "./addInfoBanner"
import deleteEventBanner from "./deleteEventBanner"
import deleteInfoBanner from "./deleteInfoBanner"

export {
	getEventBanner,
	getInfoBanner,
	addInfoBanner,
	addEventBanner,
	deleteEventBanner,
	deleteInfoBanner,
}

const banner = {
	getEventBanner,
	getInfoBanner,
	addInfoBanner,
	addEventBanner,
	deleteInfoBanner,
	deleteEventBanner,
}

export default banner
