import { decodeToken, promiseState } from "../../helper"
import { auth } from "../actions/types"
import { toast } from "react-toastify"

const { pendingState, fulfilledState, rejectedState } = promiseState

const initialState = {
	isLoading: false,
	message: null,
	errors: [],
	data: {
		token: null,
		refreshToken: null,
	},
}

const authReducers = (state = initialState, action) => {
	switch (action.type) {
		case pendingState(auth.LOGIN): {
			return Object.assign({}, state, {
				isLoading: true,
			})
		}
		case fulfilledState(auth.LOGIN): {
			if (decodeToken(action?.payload?.data?.data?.accessToken).is_admin) {
				return Object.assign({}, state, {
					isLoading: false,
					data: {
						token: action?.payload?.data?.data?.accessToken,
					},
				})
			}
			toast.error("Kamu tidak punya akses")
			return state
		}

		case rejectedState(auth.LOGIN): {
			toast.error(
				action?.payload?.response
					? action?.payload?.response.data.message
					: action?.payload?.message
			)
			return Object.assign({}, state, {
				isLoading: false,
				message: action?.payload?.response
					? action?.payload?.response.data.message
					: action?.payload?.message,
			})
		}
		case auth.LOGOUT: {
			return Object.assign({}, state, {
				data: {
					token: null,
				},
			})
		}
		default:
			return state
	}
}

export default authReducers
