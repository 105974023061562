import { Component } from "react"
import { Container, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { Icon } from "@iconify/react"
import { connect } from "react-redux"
import { video } from "../../redux/actions"
import url from "url"
import qs from "querystring"

class StreamingResource extends Component {
	getStreamingVideo() {
		this.props.getStreamingVideo()
	}

	componentDidMount() {
		this.getStreamingVideo()
	}
	render() {
		return (
			<Container>
				<Card className='card-stats mb-4 mb-xl-0'>
					<CardBody>
						<CardTitle g='h5' className='text-uppercase text-muted mb-0'>
							Streaming Video
						</CardTitle>
						<div className='d-flex justify-content-center'>
							<Link to='/admin/video-resource'>
								<div
									className='img-content-slider'
									style={{
										backgroundImage: `url("${
											"https://img.youtube.com/vi/" +
											qs.parse(
												url.parse(
													this.props.streamingResource.youtube_url || ""
												).query
											)["v"] +
											"/default.jpg"
										}")`,
									}}
								>
									<div className='edit-fill'>
										<Icon icon='eva:edit-fill' />
									</div>
								</div>
							</Link>
						</div>
					</CardBody>
				</Card>
			</Container>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		streamingResource: state.video.streamingResource,
	}
}

const mapDispatchToProps = {
	getStreamingVideo: video.getStreamingVideo,
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamingResource)
