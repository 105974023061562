import getEvents from "./getEvents"
import nextEvents from "./nextEvents"
import getEvent from "./getEvent"
import addEvent from "./addEvent"
import deleteEvent from "./deleteEvent"
import updateEvent from "./updateEvent"

export { getEvents, nextEvents, getEvent, deleteEvent }

const event = {
	getEvents,
	nextEvents,
	getEvent,
	addEvent,
	deleteEvent,
	updateEvent,
}

export default event
